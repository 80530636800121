import React, { useState } from "react";
import "./Pricing.css";
import { BsCartCheck } from "react-icons/bs";
import { useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { paymentPlan } from "../../features/payment/PaymentSlice";
import { processPayment } from "../../features/payment/PaymentSlice";
import { toast } from "react-toastify";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/config";




const Pricing = () => {

  const plan = useSelector((state) => state.payment.plan);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();


  const [quantityDC, setQuantityDC] = useState("25");
  const [durationDC, setDurationDC] = useState("30 days");
  const [paymentModeDC, setPaymentModeDC] = useState(true);
  const [priceDC, setPriceDC] = useState(0);

  const [dcStripeId, setDcStripeId] = useState(null);
  const [dcStripeName, setDcStripeName] = useState(null);

  const [quantityISP, setQuantityISP] = useState("25");
  const [durationISP, setDurationISP] = useState("30 days");
  const [paymentModeISP, setPaymentModeISP] = useState(true);
  const [priceISP, setPriceISP] = useState(0);
  const [ISPStripeId, setISPStripeId] = useState(null);
  const [ISPStripeName, setISPStripeName] = useState(null);

  const [quantityCaptcha, setQuantityCaptcha] = useState("25");
  const [durationCaptcha, setDurationCaptcha] = useState("30 days");
  const [paymentModeCaptcha, setPaymentModeCaptcha] = useState(true);
  const [priceCaptcha, setPriceCaptcha] = useState(0);

  const [CaptchaStripeId, setCaptchaStripeId] = useState(null);
  const [CaptchaStripeName, setCaptchaStripeName] = useState(null);



  const [dcStock25,setDcStock25] = useState(false);
  const [dcStock50,setDcStock50] = useState(false);
  const [dcStock75,setDcStock75] = useState(false);
  const [dcStock100,setDcStock100] = useState(false);

  const [ISPStock25,setISPStock25] = useState(false);
  const [ISPStock50,setISPStock50] = useState(false);
  const [ISPStock75,setISPStock75] = useState(false);
  const [ISPStock100,setISPStock100] = useState(false);

  const [CaptchaStock25,setCaptchaStock25] = useState(false);
  const [CaptchaStock50,setCaptchaStock50] = useState(false);
  const [CaptchaStock75,setCaptchaStock75] = useState(false);
  const [CaptchaStock100,setCaptchaStock100] = useState(false);




  const [ISPStock,setISPStock] = useState(0);
  const [CaptchaStock,setCaptchaStock] = useState(0);

  useEffect(() => {

    const DCstocksCheck =  () => {
     

      const collectionRef = collection(db,'Hosts','yscsCnLmQArao4glzmpf','DC');
      onSnapshot(collectionRef, (querySnapshot) => {
        let countTrack = [];

        querySnapshot.forEach((doc) => {
        const {count} = doc.data();
          countTrack.push(count);
        })

      
       countTrack.some((item) => item >= 25) ? setDcStock25(true) : setDcStock25(false);
       countTrack.some((item) => item >= 50) ? setDcStock50(true) : setDcStock50(false);
      countTrack.some((item) => item >= 75) ? setDcStock75(true) : setDcStock75(false);
      countTrack.some((item) => item >= 100) ? setDcStock100(true) : setDcStock100(false);
       
      }); 
    }

    DCstocksCheck();
  },[])


  useEffect(() => {

    const ISPstocksCheck =  () => {

      const collectionRef = collection(db,'Hosts','yscsCnLmQArao4glzmpf','ISP');
      onSnapshot(collectionRef, (querySnapshot) => {
        let countTrack = [];

        querySnapshot.forEach((doc) => {
        const {count} = doc.data();
          
          countTrack.push(count);
        })
        countTrack.some((item) => item >= 25) ? setISPStock25(true) : setISPStock25(false);
        countTrack.some((item) => item >= 50) ? setISPStock50(true) : setISPStock50(false);
        countTrack.some((item) => item >= 75) ? setISPStock75(true) : setISPStock75(false);
        countTrack.some((item) => item >= 100) ? setISPStock100(true) : setISPStock100(false);

      });
    }

    ISPstocksCheck();
  },[])

  useEffect(() => {

    const CaptchastocksCheck =  () => {

      const collectionRef = collection(db,'Hosts','yscsCnLmQArao4glzmpf','Captcha');
      onSnapshot(collectionRef, (querySnapshot) => {
        let countTrack = [];

        querySnapshot.forEach((doc) => {
        const {count} = doc.data();

          countTrack.push(count);
        })
        countTrack.some((item) => item >= 25) ? setCaptchaStock25(true) : setCaptchaStock25(false);
        countTrack.some((item) => item >= 50) ? setCaptchaStock50(true) : setCaptchaStock50(false);
        countTrack.some((item) => item >= 75) ? setCaptchaStock75(true) : setCaptchaStock75(false);
        countTrack.some((item) => item >= 100) ? setCaptchaStock100(true) : setCaptchaStock100(false);

      });
    }

    CaptchastocksCheck();
  },[])





  useEffect(() => {
    if (quantityDC === "25" && durationDC === "weekly") {
      setPriceDC(21);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3XrH7ckaSdVFuWEE9deKU');
        setDcStripeName('DC 25 weekly');
      }
    } else if (quantityDC === "25" && durationDC === "30 days") {
      setPriceDC(45);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3Y2H7ckaSdVFuyilWYapn');
        setDcStripeName('DC 25 monthly');
      }
    } else if (quantityDC === "25" && durationDC === "60 days") {
      setPriceDC(82);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3YFH7ckaSdVFuUjWTJ5s7');
        setDcStripeName('DC 25 quarterly');
      }
    } else if (quantityDC === "50" && durationDC === "weekly") {
      setPriceDC(40);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3eGH7ckaSdVFuPAJiv9IP');
        setDcStripeName('DC 50 weekly');
      }
    } else if (quantityDC === "50" && durationDC === "30 days") {
      setPriceDC(86);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3eGH7ckaSdVFu8YGWzXg6');
        setDcStripeName('DC 50 monthly');
      }
    } else if (quantityDC === "50" && durationDC === "60 days") {
      setPriceDC(159);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3eGH7ckaSdVFunqKxF4Ft');
        setDcStripeName('DC 50 quarterly');
      }

    } else if (quantityDC === "75" && durationDC === "weekly") {
      setPriceDC(59);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3fTH7ckaSdVFu691vS1cu');
        setDcStripeName('DC 75 weekly');
      }
    } else if (quantityDC === "75" && durationDC === "30 days") {
      setPriceDC(126);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3fTH7ckaSdVFuVHFOr3W0');
        setDcStripeName('DC 75 monthly');
      }
    } else if (quantityDC === "75" && durationDC === "60 days") {
      setPriceDC(241);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3fTH7ckaSdVFuzx2JgV1G');
        setDcStripeName('DC 75 quarterly');
      }
    } else if (quantityDC === "100" && durationDC === "weekly") {
      setPriceDC(76);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3geH7ckaSdVFuEeTYcAwU');
        setDcStripeName('DC 100 weekly');
      }
    } else if (quantityDC === "100" && durationDC === "30 days") {
      setPriceDC(162);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3geH7ckaSdVFu447nJbV6');
        setDcStripeName('DC 100 monthly');
      }
    } else if (quantityDC === "100" && durationDC === "60 days") {
      setPriceDC(312);
      if(paymentModeDC === true){
        setDcStripeId('price_1MR3geH7ckaSdVFuCctVS3jo');
        setDcStripeName('DC 100 quarterly');
      }
    }
  }, [quantityDC, durationDC]);

  useEffect(() => {
    if (quantityISP === "25" && durationISP === "weekly") {
      setPriceISP(25);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3R9H7ckaSdVFuABEfzG74');
        setISPStripeName('ISP 25 weekly');
      }
    } else if (quantityISP === "25" && durationISP === "30 days") {
      setPriceISP(55);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3R9H7ckaSdVFuxiTRsV5X');
        setISPStripeName('ISP 25 monthly');
      }
    } else if (quantityISP === "25" && durationISP === "60 days") {
      setPriceISP(105);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3R9H7ckaSdVFuDtQuMPa8');
        setISPStripeName('ISP 25 quarterly');
      }
    } else if (quantityISP === "50" && durationISP === "weekly") {
      setPriceISP(49);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3TFH7ckaSdVFuTMT3PRnn');
        setISPStripeName('ISP 50 weekly');
      }
    } else if (quantityISP === "50" && durationISP === "30 days") {
      setPriceISP(106);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3TFH7ckaSdVFuCGGZ182z');
        setISPStripeName('ISP 50 monthly');
      }
    } else if (quantityISP === "50" && durationISP === "60 days") {
      setPriceISP(203);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3TFH7ckaSdVFuYDJJzzdi');
        setISPStripeName('ISP 50 quarterly');
      }
    } else if (quantityISP === "75" && durationISP === "weekly") {
      setPriceISP(72);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3URH7ckaSdVFu1anHQ5BU');
        setISPStripeName('ISP 75 weekly');
      }
    } else if (quantityISP === "75" && durationISP === "30 days") {
      setPriceISP(154);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3URH7ckaSdVFuwPWUrxxr');
        setISPStripeName('ISP 75 monthly');
      }
    } else if (quantityISP === "75" && durationISP === "60 days") {
      setPriceISP(295);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3URH7ckaSdVFuiqEsgWMi');
        setISPStripeName('ISP 75 quarterly');
      }
    } else if (quantityISP === "100" && durationISP === "weekly") {
      setPriceISP(93);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3VlH7ckaSdVFuSFDIyx4n');
        setISPStripeName('ISP 100 weekly');
      }
    } else if (quantityISP === "100" && durationISP === "30 days") {
      setPriceISP(199);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3VlH7ckaSdVFu9HMRi5uP');
        setISPStripeName('ISP 100 monthly');
      }

    } else if (quantityISP === "100" && durationISP === "60 days") {
      setPriceISP(382);
      if(paymentModeISP === true){
        setISPStripeId('price_1MR3VlH7ckaSdVFupng0OBab');
        setISPStripeName('ISP 100 quarterly');
      }
    }
  }, [quantityISP, durationISP]);

  useEffect(() => {
    if (quantityCaptcha === "25" && durationCaptcha === "weekly") {
      setPriceCaptcha(18);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3icH7ckaSdVFu9EPidp12');
        setCaptchaStripeName('Captcha 25 weekly');
      }
    } else if (quantityCaptcha === "25" && durationCaptcha === "30 days") {
      setPriceCaptcha(38);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3jEH7ckaSdVFu2QvAVqTx');
        setCaptchaStripeName('Captcha 25 monthly');
      }
    } else if (quantityCaptcha === "25" && durationCaptcha === "60 days") {
      setPriceCaptcha(70);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3jOH7ckaSdVFub9RrwD9R');
        setCaptchaStripeName('Captcha 25 quarterly');
      }
    } else if (quantityCaptcha === "50" && durationCaptcha === "weekly") {
      setPriceCaptcha(34);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3ksH7ckaSdVFuNCGXc3I0');
        setCaptchaStripeName('Captcha 50 weekly');
      }
    } else if (quantityCaptcha === "50" && durationCaptcha === "30 days") {
      setPriceCaptcha(73);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3ksH7ckaSdVFudE9sUFsf');
        setCaptchaStripeName('Captcha 50 monthly');
      }
    } else if (quantityCaptcha === "50" && durationCaptcha === "60 days") {
      setPriceCaptcha(137);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3ksH7ckaSdVFupmo98pnQ');
        setCaptchaStripeName('Captcha 50 quarterly');
      }
    } else if (quantityCaptcha === "75" && durationCaptcha === "weekly") {
      setPriceCaptcha(49);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3mEH7ckaSdVFuRw3qngyl');
        setCaptchaStripeName('Captcha 75 weekly');
      }
    } else if (quantityCaptcha === "75" && durationCaptcha === "30 days") {
      setPriceCaptcha(106);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3mEH7ckaSdVFuDKJtR8QM');
        setCaptchaStripeName('Captcha 75 monthly');
      }
    } else if (quantityCaptcha === "75" && durationCaptcha === "60 days") {
      setPriceCaptcha(201);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3mEH7ckaSdVFuA8NPWPha');
        setCaptchaStripeName('Captcha 75 quarterly');
      }
    } else if (quantityCaptcha === "100" && durationCaptcha === "weekly") {
      setPriceCaptcha(64);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3n4H7ckaSdVFusZ2r9Vyi');
        setCaptchaStripeName('Captcha 100 weekly');
      }
        
    } else if (quantityCaptcha === "100" && durationCaptcha === "30 days") {
      setPriceCaptcha(137);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3n4H7ckaSdVFuJxAmtKjQ');
        setCaptchaStripeName('Captcha 100 monthly');
      }
    } else if (quantityCaptcha === "100" && durationCaptcha === "60 days") {
      setPriceCaptcha(263);
      if(paymentModeCaptcha === true){
        setCaptchaStripeId('price_1MR3n4H7ckaSdVFuz4s5G1KK');
        setCaptchaStripeName('Captcha 100 quarterly');
      }
    }
  }, [quantityCaptcha, durationCaptcha]);

  const purchaseHandlerDC = () => {
    dispatch(paymentPlan({plan : dcStripeId, planName : dcStripeName}));

    dispatch(processPayment());
    
  };

  const purchaseHandlerISP = () => {
    dispatch(paymentPlan({plan : ISPStripeId, planName : ISPStripeName}));
    dispatch(processPayment());
  };

  const purchaseHandlerCaptcha = () => {
    dispatch(paymentPlan({plan : CaptchaStripeId, planName : CaptchaStripeName}));
    dispatch(processPayment());
  };

  return (
    
    <div className="Pricing">
        
       
      <h1 className="Pricing-heading">Pricing Plans</h1>
      <div className="pricing-container">
        <div className="pricing-card">
          <div className="pricing-card-heading">
            <BsCartCheck className="price-card-heading-icon" />{" "}
            <h2>DC Proxy</h2>
          </div>

          <div className="pricing-card-quantity">
            <label htmlFor="quantity">Quantity</label>

            <select
              name="quantity"
              id="quantity"
              onChange={(e) => setQuantityDC(e.target.value)}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="pricing-card-duration">
            <ul>
              <li
                className={durationDC === "weekly" ? "active" : null}
                onClick={() => setDurationDC("weekly")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  Weekly
                </a>
              </li>
              <li
                className={durationDC === "30 days" ? "active" : null}
                onClick={() => setDurationDC("30 days")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  30 Day
                </a>
              </li>
              <li
                className={durationDC === "60 days" ? "active" : null}
                onClick={() => setDurationDC("60 days")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  60 Day
                </a>
              </li>
            </ul>
          </div>

          <div className="pricing-card-price">
            <p>{`Plan : $${priceDC}`}</p>
          </div>

          <div className="pricing-card-information">
            <h3>DC proxies are currently working on:</h3>
            <ul>
              <li>Amazon (Best)</li>
              <li>Apple</li>
              <li>Target</li>
              <li>PSN</li>
              <li>and other retail sites</li>
              <li>Supreme (monitor) and more!</li>
              <li className="pricing-card-information-last-li">
                These proxies will not work on FootSites/Walmart/Gamestop
              </li>
            </ul>
          </div>

          {/* <div className="pricing-card-payment-mode">
            <h4>Recurring payment</h4>
            <input
              checked={paymentModeDC}
              onChange={() => setPaymentModeDC(!paymentModeDC)}
              type="checkbox"
              id="toggle"
              className="pricing-card-payment-mode-toggle-item"
            />
            <label htmlFor="toggle"></label>
          </div> */}

          <div className="pricing-card-purchase">
            {quantityDC === '25' && (quantityDC === '25' && dcStock25 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerDC}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityDC === '50' && (quantityDC === '50' && dcStock50 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerDC}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityDC === '75' && (quantityDC === '75' && dcStock75 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerDC}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityDC === '100' && (quantityDC === '100' && dcStock100 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerDC}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
         
          </div>
        </div>

        <div className="pricing-card">
          <div className="pricing-card-heading">
            <BsCartCheck className="price-card-heading-icon" />{" "}
            <h2>ISP Proxy</h2>
          </div>

          <div className="pricing-card-quantity">
            <label htmlFor="quantity">Quantity</label>

            <select
              name="quantity"
              id="quantity"
              onChange={(e) => setQuantityISP(e.target.value)}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="pricing-card-duration">
            <ul>
              <li
                className={durationISP === "weekly" ? "active" : null}
                onClick={() => setDurationISP("weekly")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  Weekly
                </a>
              </li>
              <li
                className={durationISP === "30 days" ? "active" : null}
                onClick={() => setDurationISP("30 days")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  30 Day
                </a>
              </li>
              <li
                className={durationISP === "60 days" ? "active" : null}
                onClick={() => setDurationISP("60 days")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  60 Day
                </a>
              </li>
            </ul>
          </div>

          <div className="pricing-card-price">
            <p>{`Plan : $${priceISP}`}</p>
          </div>

          <div className="pricing-card-information">
            <h3>ISP proxies are currently working on:</h3>
            <ul>
              <li>Yeezy Supply</li>
              <li>Shopify</li>
              <li>Walmart</li>
              <li>Gamestop</li>
              <li>Nike</li>
              <li>and other sites that works on DC Proxies!</li>
              <li className="pricing-card-information-last-li">
                These proxies will not work on FootSites
              </li>
            </ul>
          </div>

          {/* <div className="pricing-card-payment-mode">
            <h4>Recurring payment</h4>
            <input
              checked={paymentModeISP}
              onChange={() => setPaymentModeISP(!paymentModeISP)}
              type="checkbox"
              id="toggle-1"
              className="pricing-card-payment-mode-toggle-item-1"
            />
            <label htmlFor="toggle-1"></label>
          </div> */}

          <div className="pricing-card-purchase">
            {/* <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerISP}>Purchase</button> */}
            {quantityISP === '25' && (quantityISP === '25' && ISPStock25 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerISP}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityISP === '50' && (quantityISP === '50' && ISPStock50 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerISP}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityISP === '75' && (quantityISP === '75' && ISPStock75 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerISP}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityISP === '100' && (quantityISP === '100' && ISPStock100 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerISP}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
          </div>
        </div>

        <div className="pricing-card">
          <div className="pricing-card-heading">
            <BsCartCheck className="price-card-heading-icon" />{" "}
            <h2>Captcha Proxy</h2>
          </div>

          <div className="pricing-card-quantity">
            <label htmlFor="quantity">Quantity</label>

            <select
              name="quantity"
              id="quantity"
              onChange={(e) => setQuantityCaptcha(e.target.value)}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="pricing-card-duration">
            <ul>
              <li
                className={durationCaptcha === "weekly" ? "active" : null}
                onClick={() => setDurationCaptcha("weekly")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  Weekly
                </a>
              </li>
              <li
                className={durationCaptcha === "30 days" ? "active" : null}
                onClick={() => setDurationCaptcha("30 days")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  30 Day
                </a>
              </li>
              <li
                className={durationCaptcha === "60 days" ? "active" : null}
                onClick={() => setDurationCaptcha("60 days")}
              >
                <a className="pricing-card-duration-btn" href="#!">
                  60 Day
                </a>
              </li>
            </ul>
          </div>

          <div className="pricing-card-price">
            <p>{`Plan : $${priceCaptcha}`}</p>
          </div>

          <div className="pricing-card-information">
            <h3>Captcha proxies are compatible with:</h3>
            <ul>
              <li>AYCD ToolBox</li>
              <li>Captcha Solvers</li>
              <li>One-Click Programs</li>
              <li>Easy One-Click</li>
              <li>Improve Scores</li>
              <li>Private</li>
              <li className="pricing-card-information-last-li">
                Unlimited Data
              </li>
            </ul>
          </div>

          {/* <div className="pricing-card-payment-mode">
            <h4>Recurring payment</h4>
            <input
              checked={paymentModeCaptcha}
              onChange={() => setPaymentModeCaptcha(!paymentModeCaptcha)}
              type="checkbox"
              id="toggle-2"
              className="pricing-card-payment-mode-toggle-item"
            />
            <label htmlFor="toggle-2"></label>
          </div> */}

          <div className="pricing-card-purchase">
            {/* <button  onClick={user === null ? () => toast.error('Not Logged In !'): purchaseHandlerCaptcha}>Purchase</button> */}
            {quantityCaptcha === '25' && (quantityCaptcha === '25' && CaptchaStock25 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerCaptcha}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityCaptcha === '50' && (quantityCaptcha === '50' && CaptchaStock50 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerCaptcha}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityCaptcha === '75' && (quantityCaptcha === '75' && CaptchaStock75 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerCaptcha}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
            {quantityCaptcha === '100' && (quantityCaptcha === '100' && CaptchaStock100 === true ? <button onClick={user === null ? () => toast.error('Not Logged In !'):purchaseHandlerCaptcha}>Purchase</button> :  <button disabled={true}>Out of stock</button>)}
          </div>
        </div>
      </div>
    </div>


  );
};

export default Pricing;
