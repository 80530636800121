import React from 'react';
import './Home.css';
import { Navbar,Main,Features,Pricing,Faqs,Discord,Footer,Subnets } from '../../components';

const Home = () => {
  return (
    <div className='Home'>
        <Navbar/>
        <Main/>
        <Features/>
        <Pricing/>
        {/* <Subnets/> */}
        <Faqs/>
        <Discord/>
        <Footer/>
    </div>
  )
}

export default Home