import React,{useEffect} from 'react';
import './Main.css'
// import launch from '../../assets/launch svg.svg'
import launch from '../../assets/undraw_outer_space_re_u9vd.svg'

const Main = () => {

  useEffect(() => {
    let line = document.querySelector('.svg-img-gg');

    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState === 'hidden') {
            line.style.animationPlayState = 'running';
      } else {}
    });
    
  }, []);


  
  const handleClick = () => {
      document.querySelector('.Pricing').scrollIntoView({behavior : 'smooth'})
}


  return (
    <section className='Main'>
      <div className='main-container'>

        <div className='main-left-content' >
            <h1><span className='inline-span' style={{display :'inline', fontSize: '25px'}}>RedHawk Proxies </span>launches your<span>success to the moon.</span></h1>
            <p>Private proxies with outstanding performance <span>on all types of websites ❤️‍🔥</span></p>
            
            <button onClick={handleClick}>Purchase</button>
        </div>

        <div className='main-right-content'>

            <img className='svg-img-gg' src={launch} alt='man launching a rocket svg'/>

        </div>
        </div>
  
    </section>
  )
}

export default Main