import {createSlice} from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
    },
    reducers: {
        AuthStateChanged: (state, action) => {
            state.user = action.payload;
        },
    },
});
export const {AuthStateChanged } = authSlice.actions;
export default authSlice.reducer;