import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadStripe } from "@stripe/stripe-js";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/config";



export const processPayment = createAsyncThunk(
  "payment/processPayment",
  async (thunkAPI, { getState }) => {
    const { auth } = getState();
    const { payment } = getState();

    const checkoutRef = collection(
      db,
      "customers",
      auth.user.uid,
      "checkout_sessions"
    );
    await addDoc(checkoutRef, {
      price: payment.plan,
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      
    }).then(async (docRef) => {
        onSnapshot(
          doc(db, "customers", auth.user.uid, "checkout_sessions", docRef.id),
          async (snap) => {
            const { sessionId } = snap.data();
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
            // allow stript to accept discount coupon code
 
            await stripe.redirectToCheckout({ sessionId });
          }
        );
      }).catch((error) => {
        console.log(error);
        alert(error.message);
        throw new Error("Some kind of Error Occurred see plans.js line 25");
      });
  }
);




export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    planName: null,
    plan: null,
    loading : false,
  },

  reducers: {
    paymentPlan: (state, action) => {
      state.plan = action.payload.plan;
      state.planName = action.payload.planName;
    },
  
  },

  extraReducers: {
    [processPayment.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
      
    },
    [processPayment.fulfilled]: (state, action) => {
      state.status = "success";
      // state.loading = false;
    },
    [processPayment.rejected]: (state, action) => {
      state.status = "failed";
      state.loading = false;
    },
  },
});

export const { paymentPlan } = paymentSlice.actions;
export default paymentSlice.reducer;
