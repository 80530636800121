import React, { useEffect,useState } from "react";
import "./ManageSubscription.css";
import { useSelector } from "react-redux";
import { TiTick } from "react-icons/ti";
import { MdOutlineSyncDisabled } from "react-icons/md";
import logo from '../../assets/logo.png'
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchSubcriptions } from "../../features/subscription/subscriptionSlice";
const ManageSubscription = () => {
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.subscription);

  const [searchedId,setSearchedId] = useState(null);
  const [searchedData,setSearchedData] = useState(null);
  const [filteredData,setFilteredData] = useState(null);
  const [loader,setLoader] = useState(false);

  const [render,setRender] = useState(null);

  const getDate = (dateStart,dateEnd) => {
    const today = new Date();
    dateEnd = dateEnd.toDate();
      dateStart = dateStart.toDate();
      var time_difference = dateEnd.getTime() - today.getTime();
      var days_difference =Math.round(time_difference / (1000 * 60 * 60 * 24));
      if(days_difference > 35){
        days_difference =   days_difference - 1
      }
      return days_difference
  }



  useEffect(() => {

      if(searchedId === null){
        setFilteredData(subscription)
      }else if
      (searchedId !== null && searchedId !== ""){
        const filtered = subscription.data.filter((item) => item.items[0].id === searchedId)
        setFilteredData({data : [...filtered]})
      }else if(searchedId === ""){
        setFilteredData(subscription)
      }

  }, [searchedData]);
  

  const handleStr = (data) => {
    return data.replace("You will get", "");
  }

  useEffect(() => {
    if(searchedId  === ''){
      setSearchedData('')
    }
  }, [searchedId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchedData(searchedId)
  }

  useEffect(() => {



    if(searchedId === null){
      setFilteredData(subscription)
      setLoader(false)
    }else if
    (searchedId !== null && searchedId !== ""){
      const filtered = subscription.data.filter((item) => item.items[0].id === searchedId)
      setFilteredData({data : [...filtered]})
      setLoader(false)
    }else if(searchedId === ""){
      setFilteredData(subscription)
      setLoader(false)
    }else{
      setLoader(false)
    }

  

    setRender(Math.random() * 1000)

  },[subscription])



  

  const getFreshData =  () => {
    setTimeout(() => {
       dispatch(fetchSubcriptions()).then(() => {
       });
      
    }, 3000);
  }




  const cancelSubscription = (subs) => {
    setLoader(true)
    const data = window.confirm("Are you sure");
    if(data === false){
      setLoader(false)
      return
    }
    try{
      fetch('https://us-central1-red-hawk-proxies.cloudfunctions.net/cancelSubscription',{
      method : 'POST',
      headers : {
        'Content-Type' : 'application/json'

      },
      body : JSON.stringify({
        subscriptionId : subs
      })
    }).then(res => res.json())
    .then(data => {
      if(data.success){
        toast.success(data.message);
      }else{
        toast.error(data.message)
      }
      getFreshData()
      
    }
    )

    }catch(error){
      toast.error('Something went wrong');
      setLoader(false)
    }
    
    
  }

  return (
    <div className="Manage-Subscription">
    <div className="Manage-Subscription-go-back">
      <Link className="Manage-Subscription-nav-button" to='/dashboard'>Go Back</Link>
    </div>
      <div className="Manage-Subscription-nav">
        <img src={logo} alt='logo' />
      </div>
      {/* <h1 className="Manage-Subscription-h1">Manage Your Subscriptions</h1> */}
      <div className="container">
  <form action="" className="search" onSubmit={handleSubmit}>
    <input className="search__input" type="search" placeholder="Plan ID" id="searchInput" onChange={(e) => setSearchedId(e.target.value)}/>

    <div className="search__icon-container">
      <label htmlFor="searchInput" className="search__label" aria-label="Search">
        <svg viewBox="0 0 1000 1000" title="Search"><path fill="currentColor" d="M408 745a337 337 0 1 0 0-674 337 337 0 0 0 0 674zm239-19a396 396 0 0 1-239 80 398 398 0 1 1 319-159l247 248a56 56 0 0 1 0 79 56 56 0 0 1-79 0L647 726z"/></svg>
      </label>

      <button className="search__submit" aria-label="Search">
        {/* <svg viewBox="0 0 1000 1000" title="Search"><path fill="currentColor" d="M408 745a337 337 0 1 0 0-674 337 337 0 0 0 0 674zm239-19a396 396 0 0 1-239 80 398 398 0 1 1 319-159l247 248a56 56 0 0 1 0 79 56 56 0 0 1-79 0L647 726z"/></svg> */}
      </button>
    </div>
  </form>
</div>

      <div className="subscription-cards">
        {filteredData?.data?.map((item, index) => {
          return (
            <div key={index} className="subscription-card">
            
              <div className="card">
              <div className="chang-new">
                  <p className='subscription-cardsubs-id'>Plan ID: {item.items[0].id}</p>
                  <p className='subscription-cardsubs-price'>Price: {item.items[0].plan.amount/100} {item.items[0].plan.currency}</p>
              </div>
            
              
                <div className="card-badges">
                
                  <span className="_badge badge_success" data-id="2">
                  <i className="bx bx-package"></i> {handleStr(item.items[0].plan.nickname)}
                  </span>
                  <span className="_badge badge_danger" data-id="2">

                    <i className="bx bx-time"></i>{
                      getDate(item.current_period_start,item.current_period_end) + " Days Left"
                    }
                  </span>
                </div>
                <div className="card-text-container">
                  <p className="card-text card-api">
                    Active since: <strong>{item.current_period_start.toDate().toDateString()}</strong> until{" "}
                    <strong>{item.current_period_end.toDate().toDateString()}</strong>
                  </p>
                  <p className="card-text card-name">
                  {!item.cancel_at_period_end &&
                   <> Next Invoice: <strong>{item.current_period_end.toDate().toDateString()}</strong>
                  </>}

                  {item.cancel_at_period_end &&
                   <> Ends On: <strong>{item.cancel_at.toDate().toDateString()}</strong>
                  </>}

                  </p>
                  <div className="card-buttom">
          
                    {!item.cancel_at_period_end && <p className="card-text card-code">
                      Auto Renewal: <strong>Enabled</strong>
                      <TiTick className="tick-active" />
                    </p>}

                    {item.cancel_at_period_end && <p className="card-text card-code">
                      Auto Renewal: <strong>Disabled</strong>
                      <MdOutlineSyncDisabled className="ren-not-active" />
                    </p>}


                   { !item.cancel_at_period_end && <button disabled={loader} className="card-button-danger" onClick={() => cancelSubscription(item.items[0].subscription)}>{loader ? <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> : 'Cancel'}</button>}
                   { item.cancel_at_period_end && <button disabled className="card-button-danger-cancelled" onClick={() => cancelSubscription(item.items[0].subscription)}>Cancelled</button>}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManageSubscription;