import React from "react";
import "./Discord.css";
import { FiTwitter } from "react-icons/fi";
import { TbBrandDiscord } from "react-icons/tb";

const Discord = () => {
  return (
    <div className="Discord">
      <div className="discord-container">
        <div className="discord-container-left">
          <h2>
            Follow us on social media for updates or{" "}
            <span style={{ display: "block" }}>join our Discord server 😊</span>
          </h2>
        </div>

        <div className="discord-container-right">
          <a
            href="https://twitter.com/RedhawkProxies"
            target="_blank"
            rel="noreferrer"
            className="discord-container-right-btn"
          >
            <FiTwitter className="discord-container-right-btn-icon" /> Twitter
          </a>
          <a
            href="https://discord.gg/JZwCjUQdkW"
            target="_blank"
            rel="noreferrer"
            className="discord-container-right-btn"
          >
            <TbBrandDiscord className="discord-container-right-btn-icon" />
            Discord
          </a>
        </div>
      </div>
    </div>
  );
};

export default Discord;
