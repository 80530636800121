import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/config";

export const fetchHistory = createAsyncThunk("paymentHistory/fetchHistory",async (thunkAPI, { getState }) => {

    const { auth  } = getState();
    const uid  = auth.user.uid;
    const data = [];

   try{
    const historyRef = collection(db, "customers",uid,"payments");
    const historyQuery = query(historyRef,where('status','==','succeeded'),orderBy('created', 'desc'),limit(10));
    const snap = await getDocs(historyQuery);
    snap.forEach((doc) => {
        data.push(doc.data());
    });
   }catch(error){
       console.log(error)
    }

    return data;
    })

export const paymentHistorySlice = createSlice({
    name: "paymentHistory",
    initialState: {
        history: null,
        status: null,
    },
    reducers: {

    },
    extraReducers: {
        [fetchHistory.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchHistory.fulfilled]: (state, action) => {
            state.status = 'success';
            state.history = action.payload;
        },
        [fetchHistory.rejected]: (state, action) => {
            state.status = 'failed';
        }

    },
});

export const {} = paymentHistorySlice.actions;

export default paymentHistorySlice.reducer;