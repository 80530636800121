import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import { fetchSubcriptions } from "../../features/subscription/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchHistory } from "../../features/paymentHistory/paymentHistorySlice";
import { Link } from "react-router-dom";
import { db } from "../../firebase/config";
import { collection, doc, getDoc } from "firebase/firestore";


const DashBoard = () => {

  


  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.subscription);
  const paymentHistory = useSelector((state) => state.paymentHistory);
  const auth = useSelector((state) => state.auth);

  // const [proxy, setProxy] = useState(
  //   "192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n192.168.1.1:3399:admin:aaxx222\n"
  // );
  const [proxy, setProxy] = useState([]);
  
  const [series, setSeries] = useState([0]);
  const userName = useSelector((state) => state.auth.user.email).split("@")[0];
  const [id,setId] = useState(null);

  useEffect(() => {
    if(subscription.data.length === 0){
    dispatch(fetchSubcriptions());
    dispatch(fetchHistory());
    }
  }, []);


  useEffect(() => {
    
  
const fetchProxies = async () => {
  const docRef = doc(db,"SubscribedUsers",auth.user.uid,'subscriptionId',subscription.currentSelectedSub);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const {proxies} = docSnap.data();
    let d = [];
    proxies.forEach((proxy) => {
      d.push(`${proxy.ip}:${proxy.port}:${proxy.username}:${proxy.password}\n`)
    })
    let k = d.join('')
    setProxy(k)
  } else {
    setProxy('Provisioning in progress, Refresh in a few minutes')
  }
}
   

fetchProxies();
  
  }, [subscription.currentSelectedSub]);


  const [options, setOptions] = useState({
    chart: {
      type: "radialBar",
      height: 350,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "75%",
          background: "#13141b",
        },
        track: {
          background: "#254FED",
        },
        dataLabels: {
          name: {
            show: true,
            color: "#254FED",
            fontSize: window.innerWidth > 500 ? '22px' : '12px',
          },
          value: {
            offsetY: 30,
            fontSize: window.innerWidth > 500 ? '22px' : '10px',
            color: "#254FED",
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#87D4F9"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Select Plan from sidebar"],
  });

  

  

  useEffect(() => {
    if (subscription.currentSelectedSub !== null) {
      const CurrentSelectedSubFiltered = subscription.data.filter(
        (sub) => sub.items[0].subscription === subscription.currentSelectedSub
      );

      const id  = CurrentSelectedSubFiltered[0].items[0].id;
      setId(id);

      let { current_period_end, current_period_start } = CurrentSelectedSubFiltered[0];
      const today = new Date();
      current_period_end = current_period_end.toDate();
      current_period_start = current_period_start.toDate();
      var time_difference = current_period_end.getTime() - today.getTime();
      var days_difference =Math.round(time_difference / (1000 * 60 * 60 * 24));

    
      if(days_difference > 35){
        days_difference =   days_difference - 1
      }

      var q = Math.abs(today - current_period_start);
      var d = Math.abs(current_period_end - current_period_start);

      //put that percentage to maximum of 2 digit float
      const percentage = ((q / d) * 100).toFixed(2);

      setSeries([percentage]);

      //set options labels to days_difference
      let optionss = {
        ...options,
        labels: [days_difference + " Days Left"],
      };
      setOptions(optionss);
    }
  }, [subscription.currentSelectedSub]);

  return (
    <div className="Dashboard">
      <div className="dashboard-nav">
        <h4>
          Welcome Back, <span>{userName}</span>
        </h4>
        <h4 className="dashboard-nav-id">Plan ID: {id}</h4>
      </div>

      <div className="dashboard-content">
        <div className="dashboard-content-div dashboard-content-graph">
          <div className="large-screen-graph">
          <Chart
            options={options}
            series={series}
            type="radialBar"
            height={350}
            width={600}
          />
          </div>

          <div className="small-screen-graph">
          <Chart
            options={options}
            series={series}
            type="radialBar"
            height={200}
            width={300}
          />
          </div>
        </div>

        <div className="dashboard-content-div dashboard-content-textarea">
          <form>
            <h3 className='dash-h3-l'>Your Proxies List</h3>
            <textarea
              name="textarea2"
              className="textarea2"
              readOnly={true}
              value={proxy}
            ></textarea>
            <div>
              <input
                title="One click transfers the code to the clipboard"
                type="button"
                value="Copy List"
                onClick={() => {
                  navigator.clipboard.writeText(proxy);
                  proxy && toast.success("Copied to clipboard");
                  !proxy && toast.error("List is empty");
                }}
              />

              <input
                title="One click transfers the code to the clipboard"
                type="button"
                value="Dowload List"
                id="dashboard-content-textarea-download"
                onClick={() => {
                  const element = document.createElement("a");
                  const file = new Blob([proxy], { type: "text/plain" });
                  element.href = URL.createObjectURL(file);
                  element.download = "proxies.txt";
                  document.body.appendChild(element); // Required for this to work in FireFox
                  element.click();
                  proxy && toast.success("Downloaded");
                  !proxy && toast.error("List is empty");
                }}
              />
            </div>
          </form>
        </div>

        <div className="dashboard-content-order-history">
        <h3 className="dashboard-content-order-history-h3">Orders History</h3>
          <table>
            <thead>
              <tr>
                <th>Invoice ID</th>
                <th>Purchase Date</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory?.history?.map((history, idx) => {
                return (
                  <tr key={idx}>
                    <td>{history.invoice}</td>
                    <td>
                      {new Date(history.created * 1000)
                        .toString()
                        .split(":")[0]
                        .slice(0, -2)}
                    </td>
                    <td>
                      {history.amount / 100} {history.currency}
                    </td>
                  </tr>
                );
              })}

          
            </tbody>
          </table>
        </div>

        <div className="dashboard-subscription-manage">
              
              <Link className="dashboard-subscription-manage-button" to='/manage-subscription'>Manage Subscriptions</Link>
            
        </div>
      </div>
      
    </div>
  );
};

export default DashBoard;
