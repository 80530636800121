import React from 'react';
import './Features.css'
import { GiEarthAmerica } from 'react-icons/gi';
import { TbThumbUp } from 'react-icons/tb';
import { FiAward,FiStar } from 'react-icons/fi';
import { RiCustomerService2Line } from 'react-icons/ri';
const Features = () => {
  return (
    <div className='Features'>
      <div className='features-container'>

        <div className='features-ist-div-text'>
          <p>With our, you can easily buy plans, manage plans, and access your proxies thanks to our user-friendly website and dashboard.</p>
        </div>

        <div className='feature'>
            <div className='feature-icon-heading'>
              <GiEarthAmerica className='feature-icon-icon'/>
              <h1 className='feature-heading-heading'>Multiple Proxy Solutions</h1>
            </div>

            <p className='feature-para'>We offer high-quality solutions that are efficient on all sites and can help you easily secure the newest releases.</p>
        </div>

        <div className='feature'>
            <div className='feature-icon-heading'>
              <TbThumbUp className='feature-icon-icon'/>
              <h1  className='feature-heading-heading'>Reliability</h1>
            </div>

            <p className='feature-para'>99.99% uptime. No mISPer what, you can always count on our proxies for any drop.</p>
        </div>

        <div className='feature'>
            <div className='feature-icon-heading'>
              <FiAward className='feature-icon-icon'/>
              <h1  className='feature-heading-heading'>Dedicated Pools</h1>
            </div>

            <p className='feature-para'>We offer sizable proxy pools that are completely exclusive to our customers.</p>
        </div>

        <div className='feature'>
            <div className='feature-icon-heading'>
              <FiStar className='feature-icon-icon'/>
              <h1  className='feature-heading-heading'>Quality</h1>
            </div>

            <p className='feature-para'>Our IPs are the best on the market, enabling you to safeguard your checkouts by avoiding prohibitions on any website.</p>
        </div>

        <div className='feature'>
            <div className='feature-icon-heading'>
              <RiCustomerService2Line className='feature-icon-icon'/>
              <h1  className='feature-heading-heading'>Customer Service</h1>
            </div>

            <p className='feature-para'>Our support team is available online 24/7 to meet all your needs and expectations to ensure your experience with us is a positive one.</p>
        </div>

      </div>
    </div>
  )
}

export default Features