import React,{useState,useEffect} from 'react';
import './Sidebar.css'
import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom';
import { TfiWorld } from 'react-icons/tfi';
import { useSelector,useDispatch } from 'react-redux';
import { setCurrentSelectedSub } from '../../features/subscription/subscriptionSlice';
const Sidebar = () => {
  const dispatch = useDispatch();


  const [dcShow, setDcShow] = useState(true);
  const [ISPShow, setISPShow] = useState(true);
  const [CaptchaShow, setCaptchaShow] = useState(true);

  const [indexColor,setIndexColor] = useState({name:'DC',idx : 0});


  const subscription = useSelector(state => state.subscription);
  
  const clickHandler = (data) => {
    const {name,idx,subs} = data;
    setIndexColor({name,idx});
    dispatch(setCurrentSelectedSub(subs));
  }

 useEffect(() => {

  if(subscription.dcProxies.length > 0){
    setIndexColor({name:'DC',idx : 0});
  }else if(subscription.ISPProxies.length > 0){
    setIndexColor({name:'ISP',idx : 0});
  }else if(subscription.CaptchaProxies.length > 0){
    setIndexColor({name:'Captcha',idx : 0});
  }
    
  },[])


  return (
    <div className='Sidebar'>
      <div className='Sidebar-logo'>
        <Link to='/'><img  src={logo}  alt='hawk logo of website' /></Link>
      </div>

      <div className='Sidebar-links'>
        <h3>ISP</h3>

        <h4 onClick={() => setDcShow(!dcShow)}><TfiWorld className=''/> DC Proxies</h4>
        <ul style={{display : dcShow ? 'block' : 'none'}}>
         {subscription.dcProxies.map((proxy,index) => (
            <li onClick={() => clickHandler({name : 'DC',idx : index,subs : proxy.items[0].subscription})} className={indexColor.name === 'DC' && indexColor.idx === index ? 'active-tab' : null} key={index}>{proxy.items[0].price.product.name}</li>
          ))}
        </ul>
        <h4 onClick={() => setISPShow(!ISPShow)}><TfiWorld/> ISP Proxies</h4>
        <ul style={{display : ISPShow ? 'block' : 'none'}}>
          {subscription.ISPProxies.map((proxy,index) => (
            <li onClick={() => clickHandler({name : 'ISP',idx : index,subs : proxy.items[0].subscription})} /*onClick={() => setIndexColor({name : 'ISP', idx : index})}*/ className={indexColor.name === 'ISP' && indexColor.idx === index ? 'active-tab' : null} key={index}>{proxy.items[0].price.product.name}</li>
          ))}
        </ul>
        <h4 onClick={() => setCaptchaShow(!CaptchaShow)}><TfiWorld/> Captcha Proxies</h4>
        <ul style={{display : CaptchaShow ? 'block' : 'none'}}>
          {subscription.CaptchaProxies.map((proxy,index) => (
            <li onClick={() => clickHandler({name : 'Captcha',idx : index,subs : proxy.items[0].subscription})} className={indexColor.name === 'Captcha' && indexColor.idx === index ? 'active-tab' : null} key={index}>{proxy.items[0].price.product.name}</li>
          ))}

        </ul>

        

      </div>

    
     
    </div>
  )
}

export default Sidebar