import React from "react";
import './Login.css'
import {signInWithPopup,createUserWithEmailAndPassword,signInWithEmailAndPassword} from "firebase/auth";
import { auth,provider } from "../../firebase/config";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch} from "react-redux";
import { AuthStateChanged } from "../../features/auth/authSlice";

import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

  const dispatch = useDispatch();


  const navigate = useNavigate();
 

  const [email,setEmail] = React.useState('');
  const [password,setPassword] = React.useState('');
  const [confirmPassword,setConfirmPassword] = React.useState('');


  const [loginEmail,setLoginEmail] = React.useState('');
  const [loginPassword,setLoginPassword] = React.useState('');


  const signInWithGoogle = () => {
  
    signInWithPopup(auth,provider)
      .then(() => {
        toast.success("You are logged in !");
        dispatch(AuthStateChanged(true));
        navigate('/');
      })
      .catch((error) => {
        toast.error(error)
      });
  };

  const signUpWithEmail =  () => {

    //check if email is valid or not
    if(!email.includes('@')){
      toast.error('Please enter a valid email');
      return;
    }

    if(password === confirmPassword){
   
     createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
     
        toast.success('Your account has been created !')
        dispatch(AuthStateChanged(true));
        navigate('/')
      }).catch(error => {
          
        let mess = error.message.replace('Firebase:', '')
          toast.error(mess);
      })
    }else{
      toast.error('Passwords do not match');
  };
  
}

//create a function to login firebase user with email and password
const signInWithEmail = () => {
  signInWithEmailAndPassword(auth, loginEmail, loginPassword)
    .then((userCredential) => {
      toast.success("You are logged in !")
      // Signed in
      dispatch(AuthStateChanged(true));
      navigate('/');
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);  
    });
};



  return (
    <div className="Login">
    
      <div className="container">
        <input id="input" className="input" type="checkbox" />
        <label htmlFor="input" className="toggle">
          <span className="text sign-text">Log in</span>
          <span className="icon">
            <svg
              className="arrow"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 16 16"
              height={32}
              width={32}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
          </span>
          <span className="text log-text">Sign Up</span>
        </label>
        <div className="card">
        <div className="content log">
            <h2 className="title">Log In</h2>
            <div className="fields">
              <label className="field">
                <div className="icon">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={12} cy={12} r={4} />
                    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94" />
                  </svg>
                </div>
                <input
                  type="email"
                  className="email"
                  placeholder="Your Email"
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
              </label>
              <label className="field">
                <div className="icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z" />
                  </svg>
                </div>
                <input
                  type="password"
                  className="password"
                  placeholder="Your Password"
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
              </label>
              <p className="forgot-password"><Link className="forgot-pass-link" to='/password-reset'>Forgot Password ?</Link></p>
            </div>
            <div className="submit">
              <button type="submit" className="button-submit" onClick={signInWithEmail}>
                Submit
              </button>
            </div>

            <div className="submit">
              <button type="submit" className="button-submit button-google" onClick={signInWithGoogle}>
                Sign in with Google
              </button>
            </div>
          </div>
          <div className="content sign">
            <h2 className="title">Sign Up</h2>
            <div className="fields">
             <label className="field">
                <div className="icon">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={12} cy={12} r={4} />
                    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94" />
                  </svg>
                </div>
                <input
                  type="email"
                  className="email"
                  placeholder="Your Email"
                  onChange={e => setEmail(e.target.value)}
                />
              </label>
              <label className="field">
                <div className="icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z" />
                  </svg>
                </div>
                <input
                  type="password"
                  className="password"
                  placeholder="Your Password"
                  onChange={e => setPassword(e.target.value)}
                />
              </label>

              <label className="field">
                <div className="icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z" />
                  </svg>
                </div>
                <input
                  type="password"
                  className="password"
                  placeholder="Confirm Password"
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </label>
            </div>
            
            <div className="submit">
              <button type="submit" className="button-submit" onClick={signUpWithEmail}>
                Submit
              </button>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Login;
