import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/config";

export const fetchSubcriptions = createAsyncThunk("subscription/fetchSubscription", async(thunkAPI, { getState }) => {
    const { auth  } = getState();
    const uid  = auth.user.uid;
    console.log('fetch subs')

  try{
 

    const subsRef = collection(db, "customers",uid,"subscriptions");
    const que = query(subsRef,where('status','==','active'),orderBy("created","desc"));
    const snap = await getDocs(que);

    const Dc = [];
    const Captcha = [];
    const ISP = [];
    const data = [];
 
    snap.forEach((doc) => {
        

        data.push(doc.data());
        if(doc.data().items[0].price.product.name.includes("DC")){
            Dc.push(doc.data());
        }else if(doc.data().items[0].price.product.name.includes('Captcha')){
            Captcha.push(doc.data());
        }else if(doc.data().items[0].price.product.name.includes('ISP')){
            ISP.push(doc.data());
        }
  
    }); 

    return {Dc,ISP,Captcha,data};


  }catch(error){
    console.log('error',error)
  }

})

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    data : [],
   dcProxies : [],
   ISPProxies : [],
   CaptchaProxies : [],
   status : null,
   currentSelectedSub : null,

  },
  reducers: {
    setCurrentSelectedSub : (state,action) => {
        state.currentSelectedSub = action.payload;
    }
  },
    extraReducers: {
        [fetchSubcriptions.pending]: (state, action) => {
            state.status = "loading";
            
        },
        [fetchSubcriptions.fulfilled]: (state, action) => {
            state.status = "success";
           
            state.dcProxies = action.payload.Dc;
            state.ISPProxies = action.payload.ISP;
            state.CaptchaProxies = action.payload.Captcha;
            state.data = action.payload.data;
            if(action.payload.Dc.length > 0){
            state.currentSelectedSub = action?.payload?.Dc[0]?.items[0]?.subscription
            }else if(action.payload.ISP.length > 0){
                state.currentSelectedSub = action?.payload?.ISP[0]?.items[0]?.subscription
            }else if(action.payload.Captcha.length > 0){
                state.currentSelectedSub = action?.payload?.Captcha[0]?.items[0]?.subscription
            }
        },
        [fetchSubcriptions.rejected]: (state, action) => {
            state.status = "failed";
            
        },
    }
});

export const {setCurrentSelectedSub} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
