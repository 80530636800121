import React,{useEffect,useState} from "react";
import "./Faqs.css";

const Faqs = () => {

  const [faqs,setFaqs] = useState(0);



useEffect(() => {
  const li = document.querySelectorAll('.faqs-accordion-item');

  li.forEach(item => {
    item.addEventListener('click', () => {
      item.classList.toggle('the-active');

     //item have a child p with class name faq-text. if its display none make it display block if its display black make it display none.

      const faqText = item.querySelector('.faq-text');


      if(faqText.style.display === 'block'){
        faqText.style.display = 'none';
        item.classList.remove('transition');
      }
      else{
        faqText.style.display = 'block';
        item.classList.add('transition');
      }


    })
  })

  // setTimeout(() => {
    
  //   setFaqs(1)

  // },1000)
  
}, [faqs]);
  
 
  return (
    <div className="Faqs">
      <section>
        <div className="" >
          <ul className="faq-list"  >
            <li className="faqs-accordion-item">
              <h4 className="faq-heading">
                {" "}
                How do I purchase proxies??{" "}
              </h4>
              <p className="read faq-text">
              Its very simple. just click on purchase button you will be dragged down to our pricing section. there you can select the plan you want and click on purchase button. you will be redirected to our payment page. there you can pay using your credit card. after payment you will be redirected to our dashboard where you can see your proxies.
              </p>
            </li>
            <li className="faqs-accordion-item">
              <h4 className="faq-heading">
                {" "}
                When will proxy plans restock?{" "}
              </h4>
              <p className="read faq-text">
                You can follow us on discord and message us to confirm when out of stock proxies will be restocked.
              </p>
            </li>
            <li className="faqs-accordion-item">
              <h4 className="faq-heading">
                {" "}
                What type of authentication is available??{" "}
              </h4>
              <p className="read faq-text">
               User:Pass Authentication
              </p>
            </li>
            <li className="faqs-accordion-item">
              <h4 className="faq-heading">
              Where are the proxies located?
              </h4>
              <p className="read faq-text">
              Hosted on Ashburn,VA. These are pools offered that have been handcrafted to target certain sites, such as Footsites, YeezySupply, and Shoepalace. Low Latency.
              </p>
            </li>
            <li className="faqs-accordion-item">
              <h4 className="faq-heading">
              When will my proxies be delivered?
              </h4>
              <p className="read faq-text">
              Proxies are delivered instantly to your dashboard upon purchase. You will be able to see proxies on the dashboard immediately after purchase. Please reach out to support on discord if you're not seeing it.
              </p>
            </li>

            <li className="faqs-accordion-item">
              <h4 className="faq-heading">
              Where can I get additional support?
              </h4>
              <p className="read faq-text">
              Support is available in the Discord server via a ticket system. There are quick guides found under the Guidance section on the dashboard.
              </p>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Faqs;
