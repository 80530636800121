import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import paymentReducer from './features/payment/PaymentSlice'
import subscriptionReducer from './features/subscription/subscriptionSlice'
import paymentHistoryReducer from './features/paymentHistory/paymentHistorySlice'

export default configureStore({
  reducer: {
    auth : authReducer,
    payment : paymentReducer,
    subscription : subscriptionReducer,
    paymentHistory : paymentHistoryReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})