import React, { useEffect } from "react";
import "./PasswordReset.css";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/config";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const PasswordReset = () => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState(null);

  useEffect(() => {
    setMessage(null);
    setEmail(null);
  }, []);

  const clickHandler = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Check your email for further instructions");
      toast.success("Success");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        toast.error("No user found with this email");
      } else if (error.code === "auth/invalid-email") {
        toast.error("Invalid email");
      } else if (error.code === "auth/weak-password") {
        toast.error("Password is too weak");
      } else {
        toast.error(error.message);
      }
    }
  };
  return (
    <div className="Password-Reset">
      {!message && <h1>Please Enter Your Email</h1>}

      {!message && (
        <div className="password-reset-input-container">
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={clickHandler}>Send</button>
          <Link className="reset-go-back-button-1" to="/login">
            Go Back
          </Link>
        </div>
      )}

      {message && (
        <div className="message">
          <p>{message}</p>
          <p>Please make sure to check spam if mail is not found in inbox.</p>
          <Link className="reset-go-back-button" to="/login">
            Go Back
          </Link>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
