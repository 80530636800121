import React, { useEffect, useState } from "react";
import "./App.css";
import { onSnapshot } from "firebase/firestore";
import { Routes, Route, Navigate } from "react-router-dom";
import { Home, Signin, Dashboard, PasswordReset,ManageSubscription } from "./Pages";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { auth, db } from "./firebase/config";
import { AuthStateChanged } from "./features/auth/authSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection } from "firebase/firestore";
import { CircleSpinnerOverlay } from "react-spinner-overlay";

import axios from "axios";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.payment.loading);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      dispatch(AuthStateChanged(user));
      unsub();
    });
  }, [user]);

  useEffect(() => {
    if (user !== null) {
      const subsRef = collection(db, "customers", user.uid, "subscriptions");

      // const q = query(subsRef,where("status", "==", "active"));

      onSnapshot(subsRef, (snap) => {
        snap.docs.forEach((doc) => {});
      });
    }
  }, [user?.uid]);






  return (
    <div className="App">

      <CircleSpinnerOverlay
        loading={loading}
        overlayColor="rgba(0,153,255,0.2)"
      />

      <ToastContainer position="top-center" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={user === null ? <Signin /> : <Navigate to="/" />}
        />
        <Route
          path="/dashboard"
          element={user === null ? <Navigate to="/" /> : <Dashboard />}
        />
        <Route
          path="/password-reset"
          element={user === null ? <PasswordReset /> : <Navigate to="/" />}
        />
        <Route path='/manage-subscription' element={<ManageSubscription/>}/>
      </Routes>
    </div>
  );
}

export default App;
