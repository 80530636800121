import React,{useEffect} from 'react';
import './Navbar.css'
import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { signOut } from "firebase/auth";
import { auth } from '../../firebase/config';


const Navbar = () => {

    const user = useSelector((state) => state.auth);
    const logout = () => {
       signOut(auth)
       window.location.reload();

    }

    useEffect(() => {
      
    }, [user]);



    const handleClick = (scrolled) => {

        if(scrolled === 'Pricing'){
            document.querySelector('.Pricing').scrollIntoView({behavior : 'smooth'})
        }else if(scrolled === 'Subnets'){
            document.querySelector('.Subnets').scrollIntoView({behavior : 'smooth'})

        }
        else{
            const div = document.querySelector(`.${scrolled}`);
            div.scrollIntoView({block: 'center', behavior: 'smooth'})

        }
    }

  return (
    <nav className='Navbar'>
        <div className='navbar-logo'>
            <img src={logo} alt='hawk logo'  onClick={() => handleClick('Main')}/> 
        </div>

        <div className='navbar-links'>
            <ul>
                <li onClick={() => handleClick('Main')}>Home</li>
                <li onClick={() => handleClick('Features')}>Features</li>
                <li onClick={() => handleClick('Pricing')}>Pricing</li>
                {/* <li onClick={() => handleClick('Subnets')}>Subnets</li> */}
                <li onClick={() => handleClick('Faqs')}>FAQ</li>
                <li onClick={() => handleClick('Discord')}>Socials</li>
            </ul>

        </div>

        <div className='navbar-auth'>

            {user.user === null ? <Link to='/login'><button>Log in</button></Link> : <button onClick={logout}>Log out</button> }
            {user.user && <Link to='/dashboard'><button style={{marginLeft : '.5rem'}}>Dashboard</button></Link>}
        </div>
    </nav>
  )
}

export default Navbar