import React from 'react'
import { Login } from '../../components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const Signin = () => {
  const Navigate = useNavigate()
  const user = useSelector((state) => state.auth.user);

  if(user){
    Navigate('/')
  }

  if(!user){
    return (
      <div className='Signin'>
      {!user &&  <Login/>}
      </div>
    )
  }
 
}

export default Signin