import React from 'react';
import './Dashboard.css'
import { Sidebar,DashBoard } from '../../components';

const Dashboard = () => {

  return (
    <div className='Dashboard-Page'>
        <Sidebar/>
        <DashBoard/>
    </div>
  )
}

export default Dashboard